import React from 'react';
import Layout from '@shared/components/layout/layout';
import { BLANK_TEMPLATE } from '@shared/utils/constants';

const InvalidEncryption = () => {
  return (
    <Layout headerMenu={[]} template={BLANK_TEMPLATE} footerMenu={[]}>
      <section className="background-grid-black background-threads careers-threads">
        <div className="container pv120-90-60">
          <div className="row body-space">
            <div className="col-12 align-middle">
              <p className="h3-style">
                Attention! 
              </p>
              <p>Your browser and/or operating system does not support modern, secure encryption. In an effort to provide the highest degree of confidentiality and security for your personal information, we require the use of browsers and operating systems that support secure encryption. Encryption is the process for scrambling your account information as it passes between us and your computer. The encryption process is built into most Internet browsers and operating systems.</p>
              <p>Please visit the Web site that provides upgrades to your browser software and your operating system.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default InvalidEncryption;
